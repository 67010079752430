import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { URL } from "../../../tools/url";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  Delete,
  Grading,
  Info,
  MarkChatRead,
  Message,
} from "@mui/icons-material";
import { decodeJSONFromServer } from "../../../tools/admin_enums";
import ConfirmDeletePopup from "../../popups/admin/ConfirmDeletePopup";
import { useNavigate } from "react-router-dom";
import useErrorPopup from "../../../tools/hooks/showError";

const ClassificationQuestionsList = ({
  radio = false,
  chosenQuestion = null,
  setChosenQuestion = () => {},
  showDelete = false,
  editOption = false,
  addNew = false,
}) => {
  const [questions, setQuestions] = useState(null);
  const [pendingDelete, setPendingDelete] = useState(null);
  const [newQuestionName, setNewQuestionName] = useState("");
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const fetchQuestions = async () => {
    try {
      const { data } = await axios.get(
        `${URL}/api/get_all_classification_questions`
      );
      setQuestions(
        data?.classification_questions
          ? decodeJSONFromServer(data?.classification_questions)
          : []
      );
    } catch (err) {
      console.error(err);
      showError("Couldn't get classification questions.");
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const deleteQuestion = async () => {
    try {
      const { data } = await axios.post(
        `${URL}/api/delete_classification_question/`,
        {
          ...pendingDelete,
        }
      );
      if (data.res === "Success") {
        await fetchQuestions();
        setPendingDelete(null);
      } else {
        throw new Error("Couldn't delete question");
      }
    } catch (err) {
      console.error(err);
      showError(err);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      fontFamily: "Assistant",
    },
  }));

  const saveNewQuestionDisabled = useMemo(() => {
    const nameTaken = questions?.some((item) => item.name === newQuestionName);
    return !newQuestionName || nameTaken;
  }, [questions, newQuestionName]);

  const saveNewQuestion = async () => {
    if (!saveNewQuestionDisabled) {
      setSavingNew(true);
      try {
        const { data } = await axios.post(
          `${URL}/api/add_new_classification_question/`,
          {
            name: newQuestionName,
            definition: "",
            definition_history: "",
          }
        );
        if (data?.res === "Success") {
          setEditingNew(false);
          await fetchQuestions();
        } else {
          throw new Error("Couldn't save question");
        }
      } catch (err) {
        console.error(err);
        showError(`${err}`);
      }
      setSavingNew(false);
    }
  };

  const displayedQuestions = useMemo(() => {
    if (search?.length) {
      return questions.filter((q) =>
        q.id === chosenQuestion?.id || q.name.trim().toLowerCase().includes(search.trim().toLowerCase())
      );
    } else return questions;
  }, [questions, search]);

  return (
    <>
      {displayedQuestions ? (
        <>
          <div className="classification-questions-search-and-filter">
            <input
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search questions"
            />
          </div>
          <FormControl
            sx={{
              borderRadius: "15px",
              border: "1px solid #ccc",
              p: "0px 20px",
            }}
          >
            {displayedQuestions.length ? (
              displayedQuestions.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom:
                      !addNew && index === questions.length - 1
                        ? "none"
                        : "1px solid #ccc",
                    p: "8px 0",
                  }}
                  key={item.id}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: `${radio ? 0 : 5}px`,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={item.id === chosenQuestion?.id}
                          onClick={() => {
                            if (item.id === chosenQuestion?.id)
                              setChosenQuestion(null);
                            else setChosenQuestion(item);
                          }}
                          sx={
                            radio
                              ? {
                                  "&.Mui-checked": {
                                    color: "#0ae5a1",
                                  },
                                  m: "2px 2px 2px 0px",
                                  p: "2px 2px 2px 0px",
                                }
                              : {
                                  display: "none",
                                }
                          }
                        />
                      }
                      label={item.name}
                      sx={{ m: 0, cursor: radio ? "pointer" : "default" }}
                    />
                    <HtmlTooltip
                      title={
                        <div>
                          <strong>Definition:</strong>
                          <br />
                          {item.definition || (
                            <em style={{ fontStyle: "italic" }}>
                              No definition entered
                            </em>
                          )}
                        </div>
                      }
                    >
                      <Info sx={{ fontSize: "15px", m: "2px 0px 0px 5px" }} />
                    </HtmlTooltip>
                    {!radio ? (
                      <>
                        {item?.tests?.length ? (
                          <>
                            <HtmlTooltip
                              title={
                                <div>
                                  <strong>Has tests</strong>
                                </div>
                              }
                            >
                              <Grading
                                sx={{ fontSize: "15px", m: "2px 0px 0px 5px" }}
                              />
                            </HtmlTooltip>
                          </>
                        ) : (
                          <></>
                        )}
                        {item?.prompts?.length ? (
                          <>
                            <HtmlTooltip
                              title={
                                <div>
                                  <strong>Has prompts</strong>
                                </div>
                              }
                            >
                              <Message
                                sx={{ fontSize: "15px", m: "2px 0px 0px 5px" }}
                              />
                            </HtmlTooltip>
                            {item?.prompts?.find((p) => p.is_active) ? (
                              <HtmlTooltip
                                title={
                                  <div>
                                    <strong>Has selected prompt</strong>
                                  </div>
                                }
                              >
                                <MarkChatRead
                                  sx={{
                                    fontSize: "15px",
                                    m: "2px 0px 0px 5px",
                                  }}
                                />
                              </HtmlTooltip>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {editOption ? (
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: "0 5px 0 0",
                          padding: "5px 10px",
                        }}
                        onClick={() => {
                          if (!savingNew) {
                            navigate(
                              `/admin/classification-questions/edit/${item.id}`
                            );
                          }
                        }}
                        disabled={savingNew}
                        title="Go to info page"
                      >
                        EDIT
                      </button>
                    ) : (
                      <></>
                    )}
                    {showDelete ? (
                      <IconButton
                        onClick={() => setPendingDelete(item)}
                        title="Delete question"
                      >
                        <Delete />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </span>
                </Box>
              ))
            ) : (
              <span style={{ margin: "10px" }}>No questions available</span>
            )}
            {addNew ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "none",
                  p: "8px 0",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {editingNew ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="New question name..."
                        style={{
                          fontSize: "inherit",
                          border: "0",
                          flex: 1,
                          outline: "none",
                          padding: "0 5px 0 0",
                        }}
                        autoFocus={true}
                        value={newQuestionName}
                        disabled={savingNew}
                        onChange={(e) => setNewQuestionName(e.target.value)}
                      ></input>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {savingNew ? (
                          <div
                            className="loader prompt-generation-loader"
                            style={{ height: "35px" }}
                          >
                            <div id="bar1" className="bar"></div>
                            <div id="bar2" className="bar"></div>
                            <div id="bar3" className="bar"></div>
                          </div>
                        ) : (
                          <>
                            <button
                              className="prompt-generation-button"
                              style={{
                                margin: "0 5px 0 0",
                                padding: "5px 10px",
                                background: saveNewQuestionDisabled
                                  ? "#ccc"
                                  : "#0ae5a1",
                              }}
                              disabled={saveNewQuestionDisabled}
                              onClick={saveNewQuestion}
                            >
                              SAVE
                            </button>
                            <button
                              className="prompt-generation-button"
                              style={{
                                margin: 0,
                                padding: "5px 10px",
                                background: "#fa7d5f",
                              }}
                              onClick={() => {
                                setEditingNew(false);
                                setNewQuestionName("");
                              }}
                            >
                              CANCEL
                            </button>
                          </>
                        )}
                      </span>
                    </span>
                  ) : (
                    <button
                      className="prompt-generation-button"
                      style={{
                        margin: 0,
                        padding: "5px 10px",
                      }}
                      onClick={() => setEditingNew(true)}
                    >
                      ADD A NEW QUESTION
                    </button>
                  )}
                </span>
              </Box>
            ) : (
              <></>
            )}
          </FormControl>
        </>
      ) : (
        "Loading questions...."
      )}
      <ConfirmDeletePopup
        open={!!pendingDelete}
        confirmDelete={deleteQuestion}
        close={() => setPendingDelete(null)}
      >
        Are you sure you want to delete the question
        {pendingDelete?.name ? (
          <>
            <br />
            {`"${pendingDelete?.name}"`}
          </>
        ) : (
          ""
        )}
        ?
        <br />
        {pendingDelete?.tests?.length || pendingDelete?.prompts?.length
          ? "This will delete all prompts and tests linked to it"
          : ""}
      </ConfirmDeletePopup>
      {ErrorPopupComponent}
    </>
  );
};

export default ClassificationQuestionsList;
