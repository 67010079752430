import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { URL } from "../../../tools/url";
import useErrorPopup from "../../../tools/hooks/showError";
import UserInfoFieldDetailsPopup from "./UserInfoFieldDetailsPopup";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

const UserInfoFieldList = ({ type, selectedFields, onChangeSelection }) => {
  const [fields, setFields] = useState(null);
  const [search, setSearch] = useState("");

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const [detailsPopupOpen, setDetailsPopupOpen] = useState(null);
  const [savingDetails, setSavingDetails] = useState(false);

  const save = async (saveObj, isNew) => {
    setSavingDetails(true);
    try {
      const { data } = await axios.post(`${URL}/api/usinfSaveField/`, {
        ...saveObj,
      });
      if (isNew) {
        setFields((prev) => [...prev, data]);
      } else {
        setFields((prev) => prev.map((f) => (f.id === data.id ? data : f)));
      }
    } catch (err) {
      console.error(err);
      showError("Couldn't save field");
    }
    setSavingDetails(false);
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const { data } = await axios.get(`${URL}/api/usinfGetFields/`);
        setFields(data.fields);
      } catch (err) {
        console.error(err);
        showError("Couldn't get fields");
        setFields([]);
      }
    };
    fetchFields();
  }, []);

  const onInitiation = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/usinfInitiateRequired/`);
      setFields(data.fields);
    } catch (err) {
      console.error(err);
      showError("Couldn't initialize fields");
      setFields([]);
    }
  };

  const fieldsToDisplay = useMemo(() => {
    if (type === "table" || !search?.length) return fields;
    return fields.filter(
      (f) =>
        selectedFields.some((selectedField) => selectedField.id === f.id) ||
        f.name.toLowerCase().includes(search.toLowerCase().trim())
    );
  }, [fields, search, type, selectedFields]);

  return (
    <>
      {fields ? (
        fields.length ? (
          type === "table" ? (
            <TableContainer component={Paper}>
              <Table aria-label="user info fields table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell colSpan={6}>Question</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field) => (
                    <TableRow key={field.id}>
                      <TableCell sx={{ display: "flex", alignItems: "center" }}>
                        {field.name}
                        {field.is_required ? (
                          <>
                            <Tooltip title="required" placement="right">
                              <span
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                &nbsp;&nbsp; ♦
                              </span>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>{field.type}</TableCell>
                      <TableCell colSpan={6}>{field.question}</TableCell>
                      <TableCell>
                        <button onClick={() => setDetailsPopupOpen(field)}>
                          EDIT
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : type === "pick-list" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="classification-questions-search-and-filter">
                <input
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search fields"
                />
              </div>
              {fieldsToDisplay.map((field) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(selectedFields || []).some(
                        (f) => f.id === field.id
                      )}
                      onChange={() =>
                        onChangeSelection(
                          field,
                          !(selectedFields || []).some((f) => f.id === field.id)
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: "#0ae5a1",
                        },
                      }}
                    />
                  }
                  label={
                    <span>
                      {field.name}{" "}
                      {field.is_required ? (
                        <>
                          <Tooltip title="required" placement="right">
                            <span
                              style={{
                                cursor: "default",
                                userSelect: "none",
                              }}
                            >
                              &nbsp;&nbsp; ♦
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  }
                  sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
                  key={field.id}
                />
              ))}
            </div>
          ) : (
            <></>
          )
        ) : (
          "no questions"
        )
      ) : (
        <div className="loader prompt-generation-loader">
          <div id="bar1" className="bar"></div>
          <div id="bar2" className="bar"></div>
          <div id="bar3" className="bar"></div>
        </div>
      )}
      {type === "table" ? (
        <>
          <button
            className="prompt-generation-button"
            onClick={onInitiation}
            style={{ margin: "10px 0", background: "#fa7d5f" }}
          >
            INITIATE REQUIRED FIELDS
          </button>
          <button
            className="prompt-generation-button"
            onClick={() => setDetailsPopupOpen("new")}
            style={{ margin: "10px 0" }}
          >
            ADD NEW QUESTION
          </button>
        </>
      ) : (
        <></>
      )}
      {ErrorPopupComponent}
      <UserInfoFieldDetailsPopup
        close={() => setDetailsPopupOpen(null)}
        field={detailsPopupOpen}
        isNew={detailsPopupOpen === "new"}
        open={!!detailsPopupOpen}
        save={save}
        saving={savingDetails}
      />
    </>
  );
};

export default UserInfoFieldList;
