//packages
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Engineering } from "@mui/icons-material";
//stores
import chat from "../stores/chat.store";
//style
import "../style/header.css";
//consts
import { URL } from "../tools/url";
import { useAuth } from "../context/AuthContext";

//this component renders the app header
const PageHeader = ({ chatRestart }) => {
  const [userOptions, setuserOptions] = useState(false);
  const { isAdmin } = useAuth();

  const handleClick = (event) => {
    setuserOptions(event);
  };

  const navigate = useNavigate();

  const reloadFromSheet = async () => {
    await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);
    alert("Data reloaded!");
  };

  return (
    <div className="page-header">
      <div className="header-logo-wrapper">
        <Link to="/">
          <img
            onClick={() => {
              if (window.location.pathname === "/chatPage") chatRestart();
              chat.endChat();
            }}
            src="/images/logo.png"
            alt="Arrows Icon"
            className="header-logo-icon"
          />
        </Link>
      </div>
      <div className="right-icons">
        {isAdmin ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <img
              onClick={() => {
                reloadFromSheet();
              }}
              src="/images/reloadSpreadsheet.png"
              alt="reload"
              className="header-reload-icon"
            />
            <Link
              className="header-reload-icon"
              title="Admin page"
              to="/admin"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Engineering sx={{ color: "#0ae5a1", fontSize: "3.5vh" }} />
            </Link>
          </div>
        ) : (
          <></>
        )}
        <img src="/images/bell.png" alt="Alerts" className="header-bell-icon" />
        <img
          onClick={() => {
            handleClick(!userOptions);
          }}
          src="/images/user.png"
          alt="User"
          className="header-user-icon"
        />
      </div>
      {userOptions ? (
        <div className="userOptions">
          <div
            className="userOption"
            style={{
              borderWidth: "5px",
              borderBottomColor: "white",
              borderTopColor: "#262D3B",
              borderLeftColor: "#262D3B",
              borderRightColor: "#262D3B",
              borderStyle: "solid",
            }}
            onClick={() => {
              // navigate("/userSettings");
            }}
          >
            <img src="/images/settings.png" alt="settings" />
            <h2>Settings</h2>
          </div>
          <div
            className="userOption"
            onClick={() => {
              chat.logout();
              navigate("/login");
            }}
          >
            <img src="/images/logout.png" alt="logout" />
            <h2>Log out</h2>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PageHeader;
