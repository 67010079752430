//packages
import React from "react";
//style
import "../../style/course.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseSkeleton = () => {
  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Skeleton
          circle={true}
          style={{
            width: "45px",
            height: "45px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "400px",
            height: "30px",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: "75px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          circle={true}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "275px",
            height: "30px",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: "75px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          circle={true}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "150px",
            height: "30px",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: "75px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          circle={true}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "225px",
            height: "30px",
          }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>
        <Skeleton
          circle={true}
          style={{
            width: "45px",
            height: "45px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "400px",
            height: "30px",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: "75px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          circle={true}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "275px",
            height: "30px",
          }}
        />
      </div>
      <div
        style={{
          marginLeft: "75px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          circle={true}
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <Skeleton
          style={{
            marginLeft: "30px",
            width: "150px",
            height: "30px",
          }}
        />
      </div>
    </div>
  );
};

export default CourseSkeleton;
