//packages
import axios from 'axios';
import { Alert, Dialog, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useGoogleLogin } from '@react-oauth/google';
//consts
import { URL } from '../tools/url';
//style
import "../style/login.css";
import chat from '../stores/chat.store';


const Login = () => {

    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState('');
    const [signupMsg, setsignupMsg] = useState('');
    const [loadingPopup, setLoadingPopup] = useState(false);



    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            getUserInfoAndLogin(codeResponse);
        },
        onError: (error) => {
            setErrMsg('Error logging in, try again')
        }
    });

    let getUserInfoAndLogin = async (user) => {
        setLoadingPopup(true);
        try {
            let userInfo = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
            //reload info from server
            // await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);

            //attempt login and create user i it doesnt already exists
            const { data } = await axios.get(`${URL}/api/login/?id=${userInfo.data.id}&email=${userInfo.data.email}&first_name=${userInfo.data.given_name}&last_name=${userInfo.data.family_name}`);
            if (data?.error) {
                setsignupMsg("to use this platform, please reach out to us at ")
            }
            else {
                const accessToken = data.res.access_token;
                localStorage.setItem('access_token', accessToken);
                window.dispatchEvent(new Event('storage'))
                chat.setSessionId(userInfo.data.email);
                setTimeout(() => navigate('/'), 1000);
            }


        }
        catch (err) {
            setErrMsg('Error logging in, try again')
        };


    }

    useEffect(() => {
        return () => setLoadingPopup(false);
    }, []);


    useEffect(() => {
        setTimeout(() => { setErrMsg('') }, 5000)
    }, [errMsg])
    return (
        <>
        <div>
            <img src="/images/loginBackground.png" alt="background" className="background" />

            <div className="content">

                <img src="/images/logo.png" alt="background" className="arrows-logo" />

                <h2>Practice Real Life Social Skills</h2>
                <h2>with our one of a kind </h2>
                <h2>Dynamic Avatar</h2>

                <button className="google-button" onClick={() => { login(); }}>
                    <img src="/images/googleLogo.png" alt="google logo" />
                    <h3>Continue with Google</h3>
                </button>

                {errMsg ?
                    <div className="err">
                        <Alert severity="error">
                            {errMsg}
                        </Alert>
                    </div> : <></>}
                {signupMsg ?
                    <div className="err" style={{cursor:'pointer'}}>
                        <Alert severity="info">
                            {signupMsg}
                            <a href="mailto:info@ar-rows.com">info@ar-rows.com</a>
                        </Alert>
                    </div> : <></>}
            </div>

        </div>
        <Dialog open={loadingPopup} sx={{ padding: "30px" }}>
            <Typography
                sx={{
                    mt: 5,
                    ml: 10,
                    mb: 5,
                    mr: 10
                }}
                variant="h6"
            >
                Logging in... 
            </Typography> 
        </Dialog> 
        </>
    );
};

export default Login;
