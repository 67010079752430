import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

const SIDEBAR_ITEMS = [
  { name: "Home", link: "/admin" },
  { name: "Courses", link: "/admin/courses" },
  { name: "Classification questions", link: "/admin/classification-questions" },
  { name: "Skills", link: "/admin/skills" },
  { name: "KPIs", link: "/admin/KPIs" },
  { name: "LLM management", link: "/admin/llm-management" },
  { name: "Conversation history", link: "/admin/conversation-history" },
  { name: "User info questions", link: "/admin/user-info-questions" },
];

const AdminSidebar = () => {
  const location = useLocation();

  return (
    <div className="admin-sidebar">
      <Box>
        <List>
          {SIDEBAR_ITEMS.map((item) => (
            <Fragment key={item.name}>
              <ListItemButton
                disabled={location.pathname === item.link}
                component={Link}
                to={item.link}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Box>
    </div>
  );
};

export default AdminSidebar;
