// style
import "../../style/convInfoPopup.css";
// mui
import { Dialog, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

const NoTokens = ({ close, open }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          width: "55vw",
          backgroundColor: "white",
          borderRadius: "1vh",
          maxHeight: "unset",
          maxWidth: "unset",
          overflow: "hidden",
          padding: "10px",
          boxSizing: "border-box",
        },
        className: "no-tokens-paper",
      }}
    >
      <div style={{position: "relative"}}>
        <IconButton
          className="exitInfo"
          onClick={close}
          sx={{ color: "#262d3b", padding: "1px" }}
        >
          <CloseRounded sx={{ fontSize: "35px" }} />
        </IconButton>
        <div
          className="homeInfoDiv"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontSize: "35px",
              fontWeight: "800",
              marginBottom: "10px"
            }}
          >
            Your free trial with Arrows has ended...
          </h2>
          {/* <h2 style={{ marginTop: vh(2), alignSelf: 'center', fontSize: vh(3), fontWeight: '600' }}>We would love to hear about your experience: please fill put the following form:</h2>
                    <a style={{ fontSize: vh(2.5), fontWeight: '400', color: '#262D3B' }} href="mailto:info@ar-rows.com">info@ar-rows.com</a> */}
          <h2
            style={{
              margin: "35px auto 10px auto",
              alignSelf: "center",
              fontSize: "25px",
              fontWeight: "600",
            }}
          >
            Want to keep going?
          </h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <h2
              style={{
                alignSelf: "center",
                fontSize: "25px",
                fontWeight: "400",
                marginRight: "5px",
              }}
            >
              {" "}
              contact us at:{" "}
            </h2>
            <a
              style={{ fontSize: "25px", fontWeight: "400", color: "#262D3B" }}
              href="mailto:info@ar-rows.com"
            >
              info@ar-rows.com
            </a>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NoTokens;
