//packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//components
import PageHeader from "../components/header";
//consts
import { URL } from "../tools/url";
//style
import "../style/courses.css";
import CoursesSkeleton from "../components/skeletons/coursesSk";
//stores
import chat from "../stores/chat.store";

const Courses = () => {
  const navigate = useNavigate();

  //useState that will hold course data
  const [load, setLoad] = useState(true);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  //fetches courses data from the server
  const fetchData = async () => {
    try {
      setLoad(true);
      const { data } = await axios.get(`${URL}/api/getCoursesList/`);
      setCourses([...data.courses]);
      setLoad(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  const navigateToCourse = (courseId) => {
    navigate(`./course/${courseId}`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PageHeader />
      <div className="courses-page">
        <h2 className="CoursesTitle">
          Hi {chat.chatSettings.name}! Select a course to practice your skills:
        </h2>
        <div className="coursesContainer">
          {load ? (
            <CoursesSkeleton />
          ) : (
            courses.map((course) => {
              return (
                <div className="courseCardWrap" key={course.courseId}>
                  <Link
                    className="courseCard"
                    to={`/course/${course.courseId}`}
                  >
                    <h3>{course.courseName}</h3>
                    <p>{course.courseDesc}</p>
                    <div className="course-card-bottom">
                      <div className="courseImgWrap">
                        {course.courseId === "careers by arrows" ? (
                          <img
                            className="courseImg"
                            src="/images/workCourse.png"
                            alt="work"
                          />
                        ) : (
                          <img
                            className="courseImg"
                            src="/images/peersCourse.png"
                            alt="peers"
                          />
                        )}
                      </div>
                      <div className="decorativeCardLine"></div>
                    </div>
                  </Link>
                  {/* {course.courseId !== "peers for young adults" && !DevMode ? <img id="comingSoon" src="/images/comingSoon.png" /> : <></>} */}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
