//packages
import React from "react";
//style
import "../../style/course.css";
//consts

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseSkeleton = () => {
  return (
    <>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          style={{
            width: "350px",
            height: "275px",
          }}
        />
      ))}
    </>
  );
};

export default CourseSkeleton;
