//packages
import React, { useEffect, useState } from "react";
import axios from "axios";
//style
import "../style/chatBar.css";
//consts
import { URL } from "../tools/url";
//components
import chat from "../stores/chat.store";
import ConvInfo from "./popups/convInfoHomePage";
import { vw } from "../tools/screen";
import { useAuth } from "../context/AuthContext";

// this component returns the session bar, with start, pause, stop and reset option to the conversation
const ChatBar = ({ chatRestart, open, setOpen, language }) => {
  const [popupInfo, setPopupInfo] = useState(false);
  const [DevMode, setDevMode] = useState(false);
  const [textMap, setTextMap] = useState({
    info: "",
    restart: "",
    spreadsheet: ""
  });

  const { isAdmin } = useAuth();

  const reloadFromSheet = async () => {
    await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);
    alert("Data reloaded!");
  };

  useEffect(() => {
    if (isAdmin) {
      setDevMode(true);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (language === "he") {
      setTextMap({
        info: "מידע",
        restart: "התחל שיחה חדשה",
        spreadsheet: "טעינת מידע"
      })
    } else {
      setTextMap({
        info: "info.",
        restart: "Restart conversation",
        spreadsheet: "Reload spreadsheet"
      })
    }
  }, [language])
  
  return (
    <>
      <ConvInfo
        open={popupInfo}
        convInfo={{
          convId: chat.chatSettings.conversationId,
          convDesc: chat.chatSettings.convoDescription,
        }}
        close={() => setPopupInfo(false)}
        canContinue
        onContinue={() => setPopupInfo(false)}
      />
      <div className="option-bar-container">
        <div
          onClick={() => {
            setPopupInfo(true);
          }}
          className="reload-container"
        >
          <img src="/images/info.png" alt="Info" className="reload-icon" />
          <p className="reload-text">{textMap.info}</p>
        </div>
        <div
          onClick={() => {
            if (chatRestart) {
              chatRestart();
            }
          }}
          className="reload-container"
        >
          <img src="/images/reload.png" alt="Reload" className="reload-icon" />
          <p className="reload-text">{textMap.restart}</p>
        </div>
        {DevMode ? (
          <div
            onClick={() => {
              reloadFromSheet();
              if (chatRestart) {
                chatRestart();
              }
            }}
            className="reload-container"
          >
            <img
              src="/images/reloadSpreadsheet.png"
              alt="ReloadSheet"
              className="reload-icon"
            />
            <p className="reload-text">{textMap.spreadsheet}</p>
          </div>
        ) : (
          <div
            className="reload-container"
            style={{ paddingLeft: vw(12) }}
          ></div>
        )}
      </div>
    </>
  );
};

export default ChatBar;
