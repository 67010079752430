import React, { useRef, useState, useEffect, Fragment } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { vh, vw } from "../../tools/screen";
import axios from "axios";
import { DOMAIN, UNITYFILES } from "../../tools/url";
function UnityComponent() {

  const { unityProvider, sendMessage } = useUnityContext({ ...UNITYFILES });

  // Flag

  const [isGreen, setIsGreen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [textVal, setTextVal] = useState('')

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const processAudio = async () => {
    let res = await axios.post(`${DOMAIN}:8081/api/generateAudioFile?text=${textVal}`);
    let audioLength = _arrayBufferToBase64(res.data.audioFile.data).length / 44100

    sendMessage("Avatar", "SendAudio", `${_arrayBufferToBase64(res.data.audioFile.data)}`);
  }






  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);


  const handleMessage = (event) => {
    // console.log(`Received message from Unity: ${event.data}`);
    if (event.data === 'Loaded') {
      setIsLoaded(false);
    }
    if (event.data === 'Done') {
      setIsGreen(true);
      setTimeout(() => {
        setIsGreen(false);
      }, 3500);
    }
  };



  // Emotions



  const handleSpeedChange = (event) => {
    const newSpeed = parseFloat(event.target.value);
    sendMessage("AnimationCTRL", "PlaySpeed", newSpeed);

  };

  function sendEmotionToUnity(emotion) {
    if (unityProvider) {
      sendMessage("AnimationCTRL", "PlayEmotion", emotion);
    }
  }


  const handleEmotionClick = (emotion) => {
    sendEmotionToUnity(emotion);
  };



  const handletextChange = (event) => {
    setTextVal(event.target.value);
  }

  const objStyle = {paddingLeft: vw(2)}

  return (
    <Fragment>
      <div style={{ height: vh(100), width: vw(100), display: 'flex', flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto' }}>
        <div style={{
          height: vh(40),
        }}>
          <Unity
            style={{
              opacity: isLoaded ? '0' : '100',
              height: vh(60), width: vh(80), alignSelf: 'center'
            }}
            unityProvider={unityProvider}
          />

        </div>

        <div style={{
          marginTop: vh(23),
        }}>

          <div style={{ display: 'flex', flexDirection: "row" }}>
            <h4 className="pe-3 opacity-25"> </h4>
            <input type="text" value={textVal} onChange={handletextChange} />
            <input type="button" value="send" id="sendInput" onClick={processAudio} />

            <div className="pe-3"
              style={{
                width: vh(2),
                height: vh(2),
                marginLeft: vh(2),
                backgroundColor: isGreen ? 'green' : 'red',
              }}
            ></div>
          </div>
          <p className="pe-4">Set Speed</p>

          <input
            type="range"
            id="speed"
            name="speed"
            min="0"
            max="5"
            step="0.1"
            onChange={handleSpeedChange}
          />
          <div style={{ display: 'flex', flexDirection: "row", width: vw(40), marginTop: vh(2) }}>
            <h4 className="pe-3 opacity-25"></h4>
            <div style={objStyle} onClick={() => handleEmotionClick("happy")}><p>Happy</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("sad")}><p>Sad</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("angry")}><p>Angry</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("think")}><p>Think</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("suprise")}><p>Suprise</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("disgusted")}><p>Disgust</p></div>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", width: vw(40), marginTop: vh(2) }}>
            <h4 className="pe-3 opacity-25"></h4>
            <div style={objStyle} onClick={() => handleEmotionClick("happy_nutral")}><p>Nutral Happy</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("sad_nutral")}><p>Nutral Sad</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("angry_nutral")}><p>Nutral Angry</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("disgusted_nutral")}><p>Nutral Disgusted</p></div>

          </div>

          <div style={{ display: 'flex', flexDirection: "row", width: vw(40), marginTop: vh(2) }}>

            <h4 className="pe-3 opacity-25"></h4>
            <div style={objStyle} onClick={() => handleEmotionClick("LookUp")}><p>LookUp</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("LookDown")}><p>LookDown</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("LookLeft")}><p>LookLeft</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("LookRight")}><p>LookRight</p></div>
          </div>

          <div style={{ display: 'flex', flexDirection: "row", width: vw(40), marginTop: vh(2) }}>

            <h4 className="pe-3 opacity-25"></h4>
            <div style={objStyle} onClick={() => handleEmotionClick("head_to_right")}><p>HeadToRight</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("head_from_right")}><p>HeadFromRight</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("head_to_left")}><p>HeadToLeft</p></div>
            <div style={objStyle} onClick={() => handleEmotionClick("head_from_left")}><p>HeadFromLeft</p></div>
          </div>


        </div>
      </div>

    </Fragment >


  );

}





// const UnityComponent = () => {
//   return (
//     <iframe
//       title="Unity Game"
//       src="/unityApp.html"
//       width="800"
//       height="600"
//       frameBorder="0"
//     />
//   );
// };

// const sendToUnity = (message) => {
//   const unityIframe = document.querySelector('iframe[title="Unity Game"]');
//   unityIframe.contentWindow.postMessage(message, '*');
// };

// sendToUnity({ type: 'custom', data: 'Hello from React!' });


export default UnityComponent;
