//packages
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//style
import "../../style/convInfoPopup.css";
//stores
import chat from "../../stores/chat.store";
//tools
import { vh, vw } from "../../tools/screen";
import Button from "../button";
// components
import ScenarioUserInfo from "../user-info/ScenarioUserInfo";
import { Dialog, IconButton } from "@mui/material";
import { Close, CloseRounded } from "@mui/icons-material";

const ConvInfo = ({
  convInfo,
  close,
  open,
  canContinue,
  userId,
  getUserInfo,
  onContinue,
}) => {
  const navigate = useNavigate();

  const [skills, setSkills] = useState([]);

  const [userInfoQuestions, setUserInfoQuestions] = useState([]);

  useEffect(() => {
    if (convInfo.skillsList) {
      setSkills(convInfo.skillsList);
    } else {
      setSkills(chat.chatSettings.skills);
    }
    setUserInfoQuestions(convInfo.userInfoFields);
  }, [convInfo]);

  const fetchQuestions = async () => {
    await getUserInfo(convInfo.convId, userId);
  };

  const colors = [
    "#D5E2E0",
    "#F8EAEC",
    "#CAE0E4",
    "#F7DDD9",
    "#F7E6DA",
    "#EEF3E9",
    "#C8C7D6",
    "#F9E0E2",
    "#E3E9DD",
    "#C4DBD9",
    "#E4E4EC",
    "#F0D7DF",
    "#FFF3EB",
  ];

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          width: "65vw",
          backgroundColor: "white",
          borderRadius: "1vh",
          maxHeight: "unset",
          maxWidth: "unset",
          overflow: "hidden",
          padding: "30px 35px",
          boxSizing: "border-box"
        },
      }}
    >
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <IconButton className="exitInfo" onClick={close} sx={{ color: "#262d3b", padding: "1px" }}>
          <CloseRounded sx={{ fontSize: "35px" }} />
        </IconButton>
        <div className="homeInfoDiv">
          <h2 className="homeConvTitle">Lesson: {convInfo.convId}</h2>

          <h2 className="homeConvDescription">{convInfo.convDesc}</h2>
          <h2
            className="homeConvDescription"
            style={{ marginTop: "25px", fontSize: "20px" }}
          >
            skills you will practice in this lesson:
          </h2>

          <div className="homeInfoskills">
            {skills.map((skill, index) => {
              let col = colors.length - 1;
              let colorDisplayId = index - Math.floor(index / col) * col;
              return (
                <div
                  key={index}
                  style={{ backgroundColor: colors[colorDisplayId] }}
                  className="homeInfoskill"
                >
                  <h2>{skill}</h2>
                </div>
              );
            })}
          </div>
          {userInfoQuestions?.length ? (
            <ScenarioUserInfo
              fields={userInfoQuestions}
              userId={userId}
              fetchQuestions={fetchQuestions}
              canStartConversation={canContinue}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="infoButtonContainer">
          {window.location.pathname !== "/" ? (
            <Button
              text="Continue"
              icon="/images/playWhite.png"
              style={{
                backgroundColor: canContinue ? "#262D3B" : "#aaa",
                cursor: canContinue ? "pointer" : "not-allowed",
                fontWeight: "400",
              }}
              alt="start"
              onClick={() => {
                if (canContinue) onContinue();
              }}
            />
          ) : (
            <Button
              text="Start"
              icon="/images/playWhite.png"
              style={{
                backgroundColor: canContinue ? "#262D3B" : "#aaa",
                cursor: canContinue ? "pointer" : "not-allowed",
                fontWeight: "400",
              }}
              alt="start"
              onClick={() => {
                if (canContinue) {
                  chat.setConversation(convInfo.convName); //Set values in chat store
                  chat.setConvoDescription(convInfo.convDesc);
                  chat.setConversationId(convInfo.convId);
                  chat.setConvSkills(skills);
                  navigate("/chatPage");
                }
              }}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConvInfo;
