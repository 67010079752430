import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../tools/url";
import chat from "../stores/chat.store";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");

  const [user, setUser] = useState(undefined); // undefined instead of null, so user isn't redirected on reload
  const [isAdmin, setIsAdmin] = useState(false);
  const [filledAllUserInfo, setFilledAllUserInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchRequiredUserInfo = async (userId) => {
    const userInfoFields = await axios.get(
      `${URL}/api/usinfGetFields/?userId=${userId}&required=true`
    );

    setFilledAllUserInfo(userInfoFields.data.filled_all);
  };

  useEffect(() => {
    // Load the access token
    const at = localStorage.getItem("access_token");
    if (at) setAccessToken(at);
    else {
      setUser(null);
      setFilledAllUserInfo(null);
      setIsAdmin(false);
    }

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if (event.key === "access_token") setAccessToken(event.newValue);
    }

    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler, false);

    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (accessToken) {
        setLoading(true);
        try {
          const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
          const { data } = await axios.get(
            `${URL}/api/getUserInfo/?id=${decodedToken.user_id}`
          );

          await fetchRequiredUserInfo(decodedToken.user_id);
          
          setUser(data.userInfo);
          setIsAdmin(data.userInfo.is_admin);

          chat.setName(data.userInfo.first_name);
          chat.setEmail(data.userInfo.username);
          chat.setSessionId(data.userInfo.username);


        } catch (error) {
          console.error("Error fetching user info:", error);
        }
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAdmin,
        loading,
        filledAllUserInfo,
        fetchRequiredUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
