import Button from "../button";
import chat from "../../stores/chat.store";
import { StepLabel, StepContent, IconButton } from "@mui/material";
import ConvInfo from "../../components/popups/convInfoHomePage";
import { URL } from "../../tools/url";
import NoTokens from "../popups/noTokens";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const ListedScenario = ({ conversation, devMode, navigate, onClick, isActive }) => {
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [noTokensPopup, setNoTokensPopup] = useState(false);

  const [tokens, setTokens] = useState(0);
  const [userInfo, setUserInfo] = useState(null);

  const [canPlay, setCanPlay] = useState(false);
  const { user } = useAuth();

  const checkTokens = async () => {
    try {
      const { data } = await axios.get(
        `${URL}/api/checkTokens/?userId=${user.id}`
      );
      return data.tokens;
    } catch (e) {
      return 1;
    }
  };

  const getRequiredUserInfo = async (scenarioId, userId) => {
    try {
      const { data } = await axios.get(
        `${URL}/api/usinfGetFields/?scenarioId=${scenarioId}&userId=${userId}`
      );
      console.log(`data?.fields`, data?.fields);
      if (data?.fields) return data.fields;
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  const startConversation = async (avatar = true) => {
    if (canPlay) {
      chat.setConversation(conversation.content.convName);
      chat.setConvoDescription(conversation.content.convDesc);
      chat.setConversationId(conversation.content.convId);
      chat.setTopic(conversation.topicName);
      chat.setConvSkills(conversation.content.skillsList)
      navigate(avatar ? "/chatPage" : "/chatPageDev");
    } else {
      if (!tokens) setNoTokensPopup(true);
      else setInfoPopupOpen(true);
    }
  };

  const getInfo = async () => {
    const userInfoFromServer = await getRequiredUserInfo(
      conversation.content.convId,
      user.id
    );
    setUserInfo(userInfoFromServer);
  };

  const getTokens = async () => {
    const tokensFromServer = await checkTokens();
    setTokens(tokensFromServer);
  };

  useEffect(() => {
    if (conversation && user.id) {
      getInfo();
      getTokens();
    }
  }, [conversation, user]);

  useEffect(() => {
    if (userInfo && !userInfo.some((item) => !item.answer.length) && tokens) {
      setCanPlay(true);
    } else {
      setCanPlay(false);
    }
  }, [userInfo, tokens]);

  return (
    <>
      <StepLabel
        sx={{
          "& *": { cursor: "pointer" },
          p: 0,
          height: "25px",
        }}
        onClick={onClick}
        StepIconComponent={() =>
          conversation.status === "done" ? (
            <img
              src="/images/lessonNumN.png"
              alt="number decore"
              className="lessonNum"
            />
          ) : (
            <div className="lessonNum"></div>
          )
        }
      >
        <h3 className="lessonsTitle">
          Scenario: {conversation.content.convName}{" "}
          <IconButton onClick={() => setInfoPopupOpen(true)} className="lessonInfo">
            <img
              src="/images/information.png"
              alt="info"              
            />
          </IconButton>
        </h3>
      </StepLabel>
      <StepContent sx={isActive ? { p: "10px 25px 0 25px" } : { /*height: 0*/ }}>
        <div
          style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
        >
          <Button
            text="Start"
            icon="/images/playWhite.png"
            onClick={() => startConversation(true)}
            style={{ margin: "0 20px" }}
          />
          {devMode && (
            <Button
              text="Start without avatar"
              icon="/images/playWhite.png"
              onClick={() => startConversation(false)}
            />
          )}
        </div>
      </StepContent>
      <ConvInfo
        convInfo={{ ...conversation.content, userInfoFields: userInfo }}
        close={() => setInfoPopupOpen(false)}
        open={infoPopupOpen}
        getUserInfo={getInfo}
        canContinue={canPlay}
        userId={user.id}
        onContinue={() => startConversation(true)}
      />
      <NoTokens
        open={noTokensPopup}
        close={() => {
          setNoTokensPopup(false);
        }}
      />
    </>
  );
};

export default ListedScenario;
