import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { ADMINMODE } from "../tools/url";
import { useEffect, useState } from "react";

function PrivateRoute({ Component, props }) {
    const { user, isAdmin, loading, filledAllUserInfo } = useAuth();
    const navigate = useNavigate();

    const [canRender, setCanRender] = useState(null);

    useEffect(() => {
        if (loading) setCanRender(false);
        else if (user) { // check if user is logged in
            if (!isAdmin && ADMINMODE) { // check if arrows is only available to admins
                setCanRender(false);
                navigate("/underMaintenance")
            } else if (!isAdmin && props?.admin) { // this specific component is an admin-only component
                setCanRender(false);
            } else if (!filledAllUserInfo && !props?.newUserForm) { // user didn't fill in all the required info
                setCanRender(false);
                navigate("/newuser");
            } else {
                setCanRender(true);
            }
        } else {
            setCanRender(false);
            if (user === null) navigate("/login"); // checked and there is no user
        }
    }, [user, isAdmin, loading, filledAllUserInfo, ADMINMODE, props]);

    return canRender ? <Component props={props} /> : null;
}

export default PrivateRoute;
