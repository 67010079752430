import ReactGA from 'react-ga4';
import { observer } from 'mobx-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useEffect } from 'react';
import PrivateRoute from './components/ProtectedComponent';
//pages
import Chat from './pages/chat';
import Login from './pages/login';
import Reload from './pages/Reload';
import GoogleFormPage from './pages/googleForm';
import AvatarTest from './components/Avatar/AvatarTest';
import CoursePage from './pages/CoursePage';
import NewUserForm from './pages/NewUserForm';
import ChapterSum from './pages/chapterSum';
import PrivacyPolicy from './pages/privacyPolicy';
import UserSettings from './pages/userSettings';
import UnderMaintenance from './pages/underMaintenance';
import ClassificationQuestions from './pages/ClassificationQuestions';
import Skills from './pages/Skills';
import Courses from './pages/courses';
import ClassificationQuestionData from './pages/ClassificationQuestionData';
import AdminHome from './pages/AdminHome';
import KPIs from './pages/KPIs';
import StaffCourses from './pages/StaffCourses';
import EditScenario from './pages/EditScenario';
import ConversationHistory from './pages/ConversationHistory';
import AdminUserInfoQuestions from './pages/AdminUserInfoQuestions';
import LLMManagement from './pages/LLMManagement'
import { AuthProvider } from './context/AuthContext';


function App() {

  ReactGA.initialize('G-7B1ETTNQMB');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute Component={Courses} />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/chatPage",
      element: <PrivateRoute Component={Chat} />,
    },
    {
      path: "/chatPageDev",
      element: <PrivateRoute Component={Chat} />,
    }, {
      path: "/googleForm",
      element: <PrivateRoute Component={GoogleFormPage} />,
    },
    {
      path: "/newUser",
      element: <PrivateRoute Component={NewUserForm} props={{ newUserForm: true }} />,
    },
    {
      path: "/runSkillTests",
      element: <Reload />,
    },
    {
      path: "/avatarTest",
      element: <AvatarTest />,
    },
    {
      path: "/course/:id",
      element: <PrivateRoute Component={CoursePage} />,
    },
    {
      path: "/aboutChapter",
      element: <PrivateRoute Component={ChapterSum} />,
    },
    {
      path: "/userSettings",
      element: <PrivateRoute Component={UserSettings} />,
    },
    {
      path: "/underMaintenance",
      element: <PrivateRoute Component={UnderMaintenance} />,
    },
    {
      path: "/admin",
      children: [
        {
          index: true,
          element: <PrivateRoute props={{ admin: true }} Component={AdminHome} />
        },
        {
          path: "/admin/classification-questions",
          children: [
            {
              index: true,
              element: <PrivateRoute props={{ admin: true }} Component={ClassificationQuestions} />
            },
            {
              path: "/admin/classification-questions/edit/:id",
              element: <PrivateRoute props={{ admin: true }} Component={ClassificationQuestionData} />
            }
          ]
        },
        {
          path: "/admin/skills",
          element: <PrivateRoute props={{ admin: true }} Component={Skills} />,
        },
        {
          path: "/admin/KPIs",
          element: <PrivateRoute props={{ admin: true }} Component={KPIs} />,
        },
        {
          path: "/admin/llm-management",
          element: <PrivateRoute props={{ admin: true }} Component={LLMManagement} />,
        },
        {
          path: "/admin/courses",
          children: [
            {
              index: true,
              element: <PrivateRoute props={{ admin: true }} Component={StaffCourses} />,
            },
            {
              path: "/admin/courses/edit-scenario/:id",
              element: <PrivateRoute props={{ admin: true }} Component={EditScenario} />
            }
          ],
        },
        {
          path: "/admin/conversation-history",
          element: <PrivateRoute props={{ admin: true }} Component={ConversationHistory} />
        },
        {
          path: "/admin/user-info-questions",
          element: <PrivateRoute props={{ admin: true }} Component={AdminUserInfoQuestions} />
        }
      ]
    },
    {
      path: "/privacyPolicy",
      element: <PrivacyPolicy />,
    },

  ]);



  return (
    <GoogleOAuthProvider clientId="521614827163-m65g5uc23vuidtjcspjt8femc0kdsn17.apps.googleusercontent.com">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default observer(App);
